import request from '@/service/lib/request';
// 抖音素材

/**
 * 分页
 * @author lb
 */
export function getMediaList(data) {
    return request({
      url: '/dy_media/get_media_list',
      method: 'post',
      data
    });
}

/**
 * 上传
 * @author lb
 */
export function uploadMedia(data) {
  return request({
    url: '/dy_media/add_media',
    method: 'post',
    data
  });
}

/**
 * 同步刷新 - 新增
 * @author lb
 */
export function refreshMedia(data) {
  return request({
    url: '/dy_media/refresh_media',
    method: 'post',
    data
  });
}